.checkoutWrapper {
  width: 100%;
}
.checkoutWrapper a {
  font-weight: 600;
}
@media only screen and (max-width: 768px) {
  .checkoutWrapper .summary {
    margin-bottom: 1rem;
 }
}
.checkoutWrapper .summary h1 {
  margin: 0 var(--chakra-space-4);
  line-height: 60px;
  font-weight: 600;
}
.checkoutWrapper .summary p {
  font-weight: 400;
  margin-top: 10px;
}
.checkoutWrapper .summary .discount p {
  margin-top: 0;
}
.checkoutWrapper .summary .line-item {
  display: flex;
  flex-direction: row;
  padding: var(--chakra-space-4) var(--chakra-space-4) 0 var(--chakra-space-4);
}
.checkoutWrapper .summary .line-item.subtotal {
  margin-top: var(--chakra-space-4);
  margin-bottom: 0;
  padding-top: 10px;
  border-top: 1px solid var(--chakra-colors-border-muted);
}
.checkoutWrapper .summary .line-item.subtotal .label, .summary .line-item.subtotal .price {
  margin-bottom: 0;
  color: var(--chakra-colors-text-muted);
}
.checkoutWrapper .summary .line-item.shipping {
  padding-top: 0;
}
.checkoutWrapper .summary .line-item.total {
  margin-top: var(--chakra-space-4);
  padding-bottom: var(--chakra-space-4);
  padding-top: var(--chakra-space-4);
  font-size: 21px;
  border-top: 1px solid var(--chakra-colors-border-muted);
  border-bottom: 1px solid var(--chakra-colors-border-muted);
}
.checkoutWrapper .summary .line-item.total .label, .summary .line-item.total .price {
  color: var(--chakra-colors-text-default);
  font-size: 24px;
  font-weight: 400;
  line-height: inherit;
}
.checkoutWrapper .summary .line-item.total .label .discounted, .summary .line-item.total .price .discounted {
  text-decoration: line-through;
  font-weight: 200;
  font-size: 18px;
  margin-right: 5px;
}
.checkoutWrapper .summary .line-item.payment-methods {
  padding-top: 15px;
  padding-bottom: 15px;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: center;
}
.checkoutWrapper .summary .line-item.payment-methods p {
  margin-bottom: 0;
  font-weight: 600;
}
.checkoutWrapper .summary .line-item.payment-methods img {
  height: 22px;
  margin-left: 10px;
}
.checkoutWrapper .summary .line-item.coupon {
  margin-bottom: 0;
  padding-top: 15px;
  border-top: 1px solid var(--chakra-colors-border-muted);
}
.checkoutWrapper .summary .line-item.coupon-input {
  flex-direction: column;
  padding-bottom: 5px;
  padding-top: 15px;
}
.checkoutWrapper .summary .line-item.discount {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid var(--chakra-colors-border-muted);
}
.checkoutWrapper .summary .line-item.discount p {
  margin-bottom: 0;
  color: var(--chakra-colors-text-success);
}
.checkoutWrapper .summary .line-item > a {
  margin: 0 10px;
}
.checkoutWrapper .summary .line-item > a:first-child {
  margin-left: 0;
}
.checkoutWrapper .summary .line-item > a:last-child {
  margin-right: 0;
}
.checkoutWrapper .summary .line-item .image-wrapper {
  width: 80px;
  height: 80px;
  margin-right: 15px;
  overflow: hidden;
  border-radius: var(--chakra-radii-md);
}
.checkoutWrapper .summary .line-item .image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  background: var(--chakra-colors-background-tint1);
}
.checkoutWrapper .summary .line-item p {
  margin-top: 0;
}
.checkoutWrapper .summary .line-item .label {
  flex: 1;
  text-align: left;
}
.checkoutWrapper .summary .line-item .product {
  color: var(--chakra-colors-text-muted);
  font-weight: 600;
}
.checkoutWrapper .summary .line-item .sku {
  font-size: 14px;
  color: var(--chakra-colors-text-muted);
}
.checkoutWrapper .summary .line-item .span {
  color: var(--chakra-colors-text-muted);
}
.checkoutWrapper .summary .line-item .count, .summary .line-item .price {
  font-size: 14px;
  padding-left: 10px;
  align-self: right;
  text-align: right;
  line-height: 24px;
}
.checkoutWrapper .summary .line-item .count {
  color: var(--chakra-colors-text-muted);
}
.checkoutWrapper .summary .line-item .price {
  color: var(--chakra-colors-text-muted);
  font-weight: bold;
}
.checkoutWrapper .Collapsible__trigger i.fa {
  transition: transform 300ms;
}
.checkoutWrapper .Collapsible__trigger.is-open i.fa {
  transform: rotateZ(180deg);
}
@media only screen and (max-width: 1024px) {
  .checkoutWrapper .summary h1 {
    margin: 0 20px;
    line-height: 80px;
 }
 .checkoutWrapper .summary .line-item {
    padding: 20px 20px 0 20px;
 }
  .checkoutWrapper .summary .line-item p {
    margin-top: 0;
 }
}
.checkoutWrapper .cohort-item {
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.checkoutWrapper .cohort-item:first-child {
  margin-top: 0;
}
.checkoutWrapper .cohort-item a {
  font-size: 14px;
}
.checkoutWrapper .cohort-item .cohort-meta {
  border: 1px solid;
  color: var(--chakra-colors-text-primary);
  border-color: var(--chakra-colors-text-primary);
  border-radius: 0.25rem;
  line-height: 1.5;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 12px;
  margin-left: 1rem;
}
.checkoutWrapper .cohort-item .chakra-radio__label {
  flex: 1;
}
.checkoutWrapper .cohort-item .cohort-meta.medium {
  color: var(--chakra-colors-text-warning);
  border-color: var(--chakra-colors-text-warning);
}
.checkoutWrapper .cohort-item .cohort-meta.low {
  color: var(--chakra-colors-text-error);
  border-color: var(--chakra-colors-text-error);
}
@media (min-width: 414px) {
  .checkoutWrapper .cohort-item .cohort-meta {
    font-size: 12px;
    padding: 10px;
 }
}
.checkoutWrapper fieldset {
  margin-bottom: 20px!important;
  border-radius: 4px;
  border: 1px solid rgba(89, 90, 135, 0.1);
}
.checkoutWrapper fieldset label {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 42px;
  padding: 10px 0;
  align-items: center;
  color: var(--chakra-colors-text-muted);
  font-weight: 400;
  overflow: hidden;
  margin-bottom: 0;
}
.checkoutWrapper fieldset label:not(:last-child) {
  border-bottom: 1px solid var(--chakra-colors-border-muted);
}
.checkoutWrapper fieldset label span {
  min-width: 125px;
  padding: 0 15px;
  text-align: right;
}
@media (max-width: 450px) {
  .checkoutWrapper fieldset label span {
    min-width: 80px;
 }
}
.checkoutWrapper fieldset .redirect label span {
  width: 100%;
  text-align: center;
}
.checkoutWrapper fieldset .select::after {
  content: "";
  position: absolute;
  width: 9px;
  height: 5px;
  right: 20px;
  top: 50%;
  margin-top: -2px;
  pointer-events: none;
}
.checkoutWrapper p.tip {
  /* margin: -10px auto 10px;
  padding: 5px 0 5px 30px; */
  font-size: 14px;
  color: var(--chakra-colors-text-muted);
  margin-bottom: 16px;
}
/* p.tip.no-margin {
  margin: 0;
} */
.checkoutWrapper .field {
  flex: 1;
  padding: 0 15px;
  background: transparent;
  font-weight: 400;
  color: var(--chakra-colors-text-default);
  outline: none;
  cursor: text;
}
@media only screen and (max-width: 768px) {
  .checkoutWrapper .field {
    padding: 0;
 }
}
.checkoutWrapper .field::-webkit-input-placeholder {
  color: var(--chakra-colors-text-muted);
}
.checkoutWrapper .field::-moz-placeholder {
  color: var(--chakra-colors-text-muted);
}
.checkoutWrapper .field:-ms-input-placeholder {
  color: var(--chakra-colors-text-muted);
}
.checkoutWrapper .plan-item {
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.checkoutWrapper .plan-item:first-child {
  margin-top: 0;
}
.checkoutWrapper .plan-item label {
  margin: 0;
}
.checkoutWrapper .plan-item input {
  flex: none;
}
.checkoutWrapper button.stripe-element {
  display: flex;
  width: 100%;
  height: 48px;
}
.checkoutWrapper button.stripe-element:disabled {
  opacity: 0.65;
  pointer-events: none;
}
.checkoutWrapper button.stripe-element.small {
  padding-left: 10px;
  padding-right: 10px;
  height: 36px;
}
.checkoutWrapper .country {
  display: flex;
  align-items: center;
}
.checkoutWrapper .country::before {
  display: inline-flex;
  content: "";
  width: 21px;
  height: 15px;
  background-position: -1000px -1000px;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.checkoutWrapper .country select {
  margin: 0 -15px 0 -30px;
}
.checkoutWrapper .country.at::before {
  background-position: -165px -10px;
}
.checkoutWrapper .country.au::before {
  background-position: -196px -10px;
}
.checkoutWrapper .country.be::before {
  background-position: -227px -10px;
}
.checkoutWrapper .country.br::before {
  background-position: -351px -10px;
}
.checkoutWrapper .country.ca::before {
  background-position: -382px -10px;
}
.checkoutWrapper .country.ch::before {
  background-position: -475px -10px;
}
.checkoutWrapper .country.cn::before {
  background-position: -41px -35px;
}
.checkoutWrapper .country.de::before {
  background-position: -134px -35px;
}
.checkoutWrapper .country.dk::before {
  background-position: -196px -35px;
}
.checkoutWrapper .country.es::before {
  background-position: -320px -35px;
}
.checkoutWrapper .country.eu::before {
  background-position: -351px -35px;
}
.checkoutWrapper .country.fi::before {
  background-position: -382px -35px;
}
.checkoutWrapper .country.fr::before {
  background-position: -413px -35px;
}
.checkoutWrapper .country.gb::before {
  background-position: -475px -35px;
}
.checkoutWrapper .country.hk::before {
  background-position: -41px -60px;
}
.checkoutWrapper .country.ie::before {
  background-position: -196px -60px;
}
.checkoutWrapper .country.it::before {
  background-position: -351px -60px;
}
.checkoutWrapper .country.jp::before {
  background-position: -444px -60px;
}
.checkoutWrapper .country.lu::before {
  background-position: -258px -85px;
}
.checkoutWrapper .country.mx::before {
  background-position: -475px -85px;
}
.checkoutWrapper .country.nl::before {
  background-position: -103px -110px;
}
.checkoutWrapper .country.no::before {
  background-position: -134px -110px;
}
.checkoutWrapper .country.nz::before {
  background-position: -165px -110px;
}
.checkoutWrapper .country.pt::before {
  background-position: -413px -110px;
}
.checkoutWrapper .country.se::before {
  background-position: -103px -135px;
}
.checkoutWrapper .country.sg::before {
  background-position: -134px -135px;
}
.checkoutWrapper .country.us::before {
  background-position: -475px -135px;
}
.checkoutWrapper input {
  border-style: none;
  outline: none;
  color: var(--chakra-colors-text-default);
}
.checkoutWrapper input:not([type="radio"]):not([type="checkbox"]) {
  flex: 1;
}
.checkoutWrapper select {
  flex: 1;
  border-style: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  color: var(--chakra-colors-text-default);
  cursor: pointer;
  background: transparent;
}
.checkoutWrapper select:focus {
  color: var(--chakra-colors-text-primary);
}
.checkoutWrapper ::-webkit-input-placeholder {
  color: var(--chakra-colors-text-muted);
}
.checkoutWrapper ::-moz-placeholder {
  color: var(--chakra-colors-text-muted);
  opacity: 1;
}
.checkoutWrapper :-ms-input-placeholder {
  color: var(--chakra-colors-text-muted);
}
.checkoutWrapper input:-webkit-autofill, select:-webkit-autofill {
  -webkit-text-fill-color: var(--chakra-colors-text-primary);
  transition: background-color 100000000s;
  -webkit-animation: 1ms void-animation-out 1s;
  animation: 1ms void-animation-out 1s;
}
.checkoutWrapper .StripeElement--webkit-autofill {
  background: transparent !important;
}
.checkoutWrapper #card-element {
  margin-top: -1px;
}
.checkoutWrapper .waitingList {
  display: flex;
  position: absolute;
  align-items: center;
  left: 0;
  top: 0;
  padding: 0 10px 0 0;
  background: var(--chakra-colors-background-default);
  height: 100%;
  width: 0%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.125s linear, width 0.25s ease-in-out;
}
.checkoutWrapper .waitingList.visible {
  width: calc(100% + 10px);
  opacity: 1;
  pointer-events: auto;
  z-index: 99;
}
.checkoutWrapper .waitingList input[type="email"] {
  width: 80%;
  height: 70%;
}
.checkoutWrapper .waitingList button {
  width: calc(20% - 20px);
  margin: 0 0 0 20px;
}
.checkoutWrapper .successCard {
  justify-content: center;
  max-width: 700px;
  margin: 0 auto;
}
.checkoutWrapper .successCard .success-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 10px;
  font-size: 1.5em;
  color: var(--chakra-colors-text-success);
}
.checkoutWrapper .successCard .success-header h1 {
  font-size: 1em;
  margin: 0;
  margin-left: 15px;
  color: var(--chakra-colors-text-success);
}
.checkoutWrapper .successCard .success-info {
  flex: 2;
}
.checkoutWrapper .successCard .divider {
  flex: 0.25;
  position: relative;
}
.checkoutWrapper .successCard .divider:after {
  content: "";
  width: 2px;
  height: 100%;
  background: var(--chakra-colors-border-muted);
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@media only screen and (max-width: 768px) {
  .checkoutWrapper .successCard .divider {
    display: none;
 }
}
.checkoutWrapper .successCard .success-invite {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media only screen and (max-width: 768px) {
  .checkoutWrapper .successCard .success-invite {
    display: none;
 }
}
.checkoutWrapper .successCard .success-invite h3 {
  text-align: center;
  margin: 0;
}
.checkoutWrapper .successCard .success-invite .invite-icons {
  margin-top: 20px;
  width: 100%;
  justify-content: space-around;
  display: flex;
}
.checkoutWrapper .purchaseSummary {
  width: 100%;
  margin-bottom: 20px;
}
.checkoutWrapper .purchaseSummary td {
  padding-top: 5px;
  padding-bottom: 5px;
}
.checkoutWrapper .purchaseSummary .item-row {
  color: var(--chakra-colors-text-muted);
}
.checkoutWrapper .purchaseSummary .total-row {
  font-weight: 600;
}
.checkoutWrapper .purchaseSummary .currency {
  text-align: right;
}
.checkoutWrapper .purchaseSummary tr:last-of-type {
  border-top: 1px solid var(--chakra-colors-border-muted);
}
.checkoutWrapper .authOptions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  flex-wrap: wrap;
}
.checkoutWrapper .authOptions p {
  margin: 0;
}
.checkoutWrapper .authOptions>div,
.checkoutWrapper .authOptions>button,
.checkoutWrapper .authOptions>p {
  margin-top: 10px;
}
.checkoutWrapper .authSuccess {
  display: flex;
  align-items: center;
  margin-bottom: 0.6rem;
}
.checkoutWrapper .authSuccess i {
  color: var(--chakra-colors-text-success);
}
.checkoutWrapper .authSuccess p {
  margin-bottom: 0;
  margin-left: 10px;
}
.checkoutWrapper .svgLogo {
  fill-opacity: 0;
  stroke: var(--chakra-colors-text-default);
  stroke-width: 20;
}
.checkoutWrapper .svgLogotype {
  fill: var(--chakra-colors-background-default);
}
.checkoutWrapper .svgLogoWhite {
  fill-opacity: 0;
  stroke: var(--chakra-colors-background-default);
  stroke-width: 20;
}
.checkoutWrapper .svgLogotypeWhite {
  fill: var(--chakra-colors-background-default);
}
.checkoutWrapper .svgLogoGrey {
  fill-opacity: 0;
  stroke: var(--chakra-colors-text-muted);
  stroke-width: 20;
}
.checkoutWrapper .svgLogotypeGrey {
  fill: var(--chakra-colors-text-muted);
}